export interface ObjectStatus {
  name: 'Обработан' | 'Не обработан' | 'Ликвидирован' | 'Дубль' | 'Не выбран';
  value?: string | null;
  color: string;
}

export default [
  {
    name: 'Обработан',
    value: 'PROCESSED',
    color: '#7eb9ff',
  },
  {
    name: 'Не обработан',
    value: 'NOTPROCESSED',
    color: '#ff9d7e',
  },
  {
    name: 'Ликвидирован',
    value: 'LIQUIDATED',
    color: '#000',
  },
  {
    name: 'Дубль',
    value: 'DUPLICATE',
    color: '#FF0000',
  },
  {
    name: 'Не выбран',
    value: null,
    color: 'transparent',
  },
] as ObjectStatus[];
