
import {
  defineComponent, onMounted, computed, ref, watch, reactive,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { MapTradeObject, ObjectOfTrade } from '@/types/ObjectOfTrade';
import { Statistic, StatusStat, TnoStat } from '@/types/Statistic';
import ColorSelector from '@/components/UI/ColorSelector.vue';
import { ObjectStatus } from '@/constants/objectStatuses';
import api from '@/api';
import debounce from 'lodash.debounce';
import { isMobile, isLocal } from '@/utils';
import { baseURL } from '@/axios';
import ContainerHeader from '../../components/ContainerHeader.vue';
import Container from '../../components/Container.vue';

interface CoordinatesRecord {
  dom: string
  coordinates: Array<Array<number[]>>,
  domCoordinates: number[],
  street: string,
}

interface FeatureRow {
  options: Record<string, (string | boolean | number)>;
  id: string;
  properties: {
    iso3166: string
    hintContent: boolean
  };
}

interface IFeatures {
  map: (feature: FeatureRow) => typeof feature;
  features: FeatureRow[];
}

export default defineComponent({
  name: 'MapPage',
  components: {
    ContainerHeader,
    Container,
    ColorSelector,
  },
  watch: {
    selectedStatus(val) {
      console.log(val);
    },
  },
  setup() {
    let myMap: any;
    let loadingObjectManager: any;
    let statistic :any;
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const currentAddress = ref(+(route.query.addressId as string) || null);
    const selectedStatus = ref<ObjectStatus>({
      name: 'Не выбран',
      color: 'transparent',
    });
    const search = computed(() => store.getters['map/GET_SEARCH']);
    const { isAdmin } = store.state.auth;
    if (isAdmin === true) {
      statistic = computed<Statistic>(() => store.state.stats.data);
    } else {
      const ugnsId = store.state.auth.userInfo.ugnsTno.id;
      statistic = reactive({
        statusStat: null as StatusStat | null,
        nalogPayer: null as StatusStat | null,
        values: null as TnoStat | null,
        async fetch() {
          const res = (await api.statistic.getForTnoById(`${ugnsId}`)).data;
          this.values = res.tnoStat;
          this.statusStat = res.statusStat;
          this.nalogPayer = res.nalogPayersStat;
        },
      });
    }
    const toggledMenu = ref(true);

    const objectsOfTrade = ref<MapTradeObject[]>();
    const currentObject = ref<ObjectOfTrade | void | null>();
    const objectFetching = ref(false);
    const mapTemplateURL = computed(() => `${baseURL}/tradeobject?bbox=%b${selectedStatus.value.value ? `&status=${selectedStatus.value.value}` : ''}${search.value ? `&search=${search.value}` : ''}&token=${localStorage.token || ''}`);

    const layers = reactive({
      marker: !!route.query.addressId || !isMobile,
      kad: false,
      kadFetch: false,
    });

    const statusColor = (status: string) => {
      switch (status) {
        // case 'INWORK':
        //   return '#50be34';
        case 'LIQUIDATED':
          return '#000';
        case 'NOTPROCESSED':
          return '#ff9d7e';
          // case 'DUPLICATE':
          //   return '#FF0000';
        default:
          return '#7eb9ff';
      }
    };

    const onObjectClick = async (e: any) => {
      // objectId – идентификатор объекта, на котором произошло событие.
      const objectId = e.get('objectId');
      currentAddress.value = objectId;
      currentObject.value = null;
      objectFetching.value = true;
      currentObject.value = (await api.objectsOfTrade.getById((objectId || ''), false)).data;
      objectFetching.value = false;
    };

    function checkMapByUgns(coordinates: string, latitude: number, longitude: number) {
      myMap = new window.ymaps.Map('map', {
        center: coordinates === '' ? store.getters['map/GET_MAP_SETTINGS'].coords : [latitude, longitude],
        // Уровень масштабирования. Допустимые значения:
        // от 0 (весь мир) до 19.
        zoom: store.getters['map/GET_MAP_SETTINGS'].zoom,
        controls: ['zoomControl'],
      },
      {
        restrictMapArea: [
          [34.01117682265811, 63.9281065785961],
          [48.31659233241421, 85.6837218129711],
        ],
      });
    }

    async function init() {
      const worker = (await api.user.info());
      const coordinates = worker.ugnsTno.point;
      let latitude;
      let longitude;
      if (coordinates != null) {
        const points = coordinates.split(',');
        latitude = parseFloat(points[0]);
        longitude = parseFloat(points[1]);
        checkMapByUgns(coordinates, latitude, longitude);
      } else {
        checkMapByUgns('', 0, 0);
      }
      window.ymaps.borders.load('001', {
        lang: 'ru',
        quality: 3,
      }).then((geojson: IFeatures) => {
        const features = geojson.features
          .filter((feature: FeatureRow) => feature.properties.iso3166 === 'KG')
          .map((feature: FeatureRow) => {
          // eslint-disable-next-line no-param-reassign
            feature = {
              ...feature,
              id: feature.properties.iso3166,
              options: {
                strokeWidth: 3,
                strokeColor: '#028A8F',
                fill: false,
                stroke: true,
                openHintOnHover: false,
                cursor: 'grab',
              },
            };
            return feature;
          });
        const objectManager = new window.ymaps.ObjectManager();
        objectManager.add(features);
        myMap.geoObjects.add(objectManager);
      });

      loadingObjectManager = new window.ymaps.LoadingObjectManager(
        mapTemplateURL.value,
        {
          // Включаем кластеризацию.
          clusterize: true,
          // Зададим опции кластерам.
          // Опции кластеров задаются с префиксом cluster.
          clusterHasBalloon: false,
          // Опции объектов задаются с префиксом geoObject.
          geoObjectOpenBalloonOnClick: false,
        },
      );
      let polygons: Array<any> = [];
      let polylabels: Array<any> = [];

      const removePolygons = () => {
        polygons.forEach((polygon: any) => myMap.geoObjects.remove(polygon));
        polygons = [];
        polylabels.forEach((polylabel: any) => myMap.geoObjects.remove(polylabel));
        polylabels = [];
      };

      const renderPolygons = async (stringCoordinates: string) => {
        try {
          layers.kadFetch = true;
          const newCoordinates = await api.objectsOfTrade.getKadBox(stringCoordinates);
          if (polygons.length) removePolygons();

          newCoordinates.data.forEach((coordinateRecord: CoordinatesRecord) => {
            const polygon = new window.ymaps.Polygon(
              coordinateRecord.coordinates,
              {},
              {
                strokeColor: '#FF0000',
                fill: false,
                stroke: true,
              },
            );

            const labelTemplate = window.ymaps.templateLayoutFactory.createClass(
              '<div style="color: #FF0000;">$[properties.iconContent]</div>',
            );

            const polylabel = new window.ymaps.Placemark(coordinateRecord.domCoordinates, {
              hintContent: coordinateRecord.street,
              iconContent: coordinateRecord.dom,
            },
            {
              iconLayout: 'default#imageWithContent',
              iconImageHref: '',
              iconImageSize: [48, 48],
              iconImageOffset: [-24, -24],
              iconContentOffset: [15, 15],
              iconContentLayout: labelTemplate,
            });
            polylabels.push(polylabel);
            polygons.push(polygon);
          });
          polygons.forEach((polygon: any) => myMap.geoObjects.add(polygon));
          polylabels.forEach((polylabel: any) => myMap.geoObjects.add(polylabel));
        } catch (e) {
          console.error(e);
        } finally {
          layers.kadFetch = false;
        }
      };

      const getPolygons = () => {
        const mapCoordinates: number[][] = myMap.getBounds();
        let mapCoordinatesToString = '';

        mapCoordinates.forEach((crd: number[]) => {
          if (mapCoordinatesToString.length) mapCoordinatesToString += ',';
          mapCoordinatesToString += crd.reverse().join(',');
        });

        return mapCoordinatesToString;
      };

      myMap.events.add('actionend', (e: any) => {
        store.commit('map/SET_MAP_SETTINGS', { coords: e.originalEvent.map.getCenter(), zoom: myMap.getZoom() });
        if (layers.kad && myMap.getZoom() > 16) {
          renderPolygons(getPolygons());
        } else {
          removePolygons();
        }
      });

      watch(() => layers.kad, (newValue) => {
        if (newValue && myMap.getZoom() > 16) {
          renderPolygons(getPolygons());
        } else {
          removePolygons();
        }
      });

      watch(toggledMenu, () => {
        setTimeout(() => myMap.container.fitToViewport(), 300);
      });

      if (layers.marker) myMap.geoObjects.add(loadingObjectManager);
      loadingObjectManager.objects.options.set({
        preset: 'islands#circleDotIcon',
      });
      loadingObjectManager.objects.events.add(['click'], onObjectClick);
    }

    function updateMap() {
      loadingObjectManager.setUrlTemplate(mapTemplateURL.value);
      loadingObjectManager.reloadData();
    }
    watch(() => layers.marker, (newValue) => {
      if (newValue) {
        myMap.geoObjects.add(loadingObjectManager);
      } else {
        myMap.geoObjects.remove(loadingObjectManager);
      }
    });
    watch(selectedStatus, () => {
      updateMap();
    });
    const debouncedSearch = debounce(() => {
      updateMap();
    }, 500);
    watch(search, () => {
      if (search.value) {
        debouncedSearch();
      } else {
        debouncedSearch.cancel();
        updateMap();
      }
    });
    onMounted(async () => {
      try {
        window.ymaps.ready(init);
        if (currentAddress.value) {
          objectFetching.value = true;
          currentObject.value = (await api.objectsOfTrade.getById(`${currentAddress.value}`, false)).data;
          objectFetching.value = false;
          const position = currentObject.value.tradeObject.address.point?.split(' ')
            .map((item: string) => +item) || null;
          // eslint-disable-next-line no-unused-expressions
          position && myMap.setCenter(position, 18);
        }
      } catch (e) {
        await api.innerProblem.saveProblemOnTheMap({ message: 'Превышен лимит запросов по яндекс карте', problemType: 'MAP_PROBLEM', isActive: true });
      }
    });

    const goToObject = () => {
      router.push(`/registry/trade-objects/${currentAddress.value}`);
    };

    const goToTNO = (id: string) => {
      router.push(`/registry/tax-payers/${id}`);
    };

    const closeObject = () => {
      currentAddress.value = null;
      currentObject.value = null;
      router.push({
        query: undefined,
      });
    };
    onMounted(() => {
      if (!isAdmin) { statistic.fetch(); }
    });

    return {
      objectsOfTrade,
      search,
      selectedStatus,
      statistic,
      currentAddress,
      currentObject,
      objectFetching,
      goToObject,
      closeObject,
      goToTNO,
      statusColor,
      layers,
      isMobile,
      toggledMenu,
      isAdmin,
    };
  },
});
