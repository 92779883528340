
import {
  defineComponent, onUnmounted, PropType, ref, watch,
} from 'vue';
import objectStatuses, { ObjectStatus } from '../../constants/objectStatuses';

export default defineComponent({
  name: 'ColorSelector',
  props: {
    exclude: { type: String, default: '' },
    modelValue: {
      type: Object as PropType<ObjectStatus>,
      default: () => ({ name: 'Не выбран', value: null, color: 'transparent' }),
      required: true,
    },
  },
  setup(props, { emit }) {
    const showMenu = ref(false);

    const itemClick = (status: ObjectStatus) => {
      emit('update:modelValue', status);
      showMenu.value = false;
    };

    const documentClickListener = () => {
      showMenu.value = false;
    };

    watch(showMenu, (val) => {
      if (val) document.addEventListener('click', documentClickListener);
      else document.removeEventListener('click', documentClickListener);
    });

    onUnmounted(() => {
      document.removeEventListener('click', documentClickListener);
    });

    return { objectStatuses, showMenu, itemClick };
  },
});
